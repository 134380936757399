import { mapCollection, sortByString } from '../../utils/collection.utils';
import { Activity } from '../activity';

export class ActivityBuilder {
  static fromJson(json: any): Activity {
    return json
      ? new Activity(
        json.hash,
        json.contactEmail,
        json.contactPerson,
        json.contactTelephone,
        json.description,
        json.image,
        json.links,
        json.location,
        json.priceNonSubscribers,
        json.priceSubscribers,
        json.startTimes,
        json.title,
        json.clubName,
        json.weekdayCodes,
      )
      : null;
  }

  static fromList(list: any): Activity[] {
    return Array.isArray(list)
      ? sortByString('title', mapCollection(ActivityBuilder.fromJson, list))
      : [];
  }
}
