import { Benefit } from './benefit';
import { Pathology } from './pathology';
import { Prescription } from './prescription';

export class ApproachPrescription {
  constructor(
    public hash?: string,
    public prescriptionHash?: string,
    public activityClub?: boolean,
    public activityCourse?: boolean,
    public morningShift?: boolean,
    public afternoonShift?: boolean,
    public monday?: boolean,
    public tuesday?: boolean,
    public wednesday?: boolean,
    public thursday?: boolean,
    public friday?: boolean,
    public saturday?: boolean,
    public sunday?: boolean,
    public outdoor?: boolean,
    public indoor?: boolean,
    public escolar?: boolean,
    public federated?: boolean,
    public social?: boolean,
    public instalationFadura?: boolean,
    public instalationGobela?: boolean,
    public instalationAndraMari?: boolean,
    public instalationRke?: boolean,
    public instalationOthers?: boolean,
    public benefits?: Benefit[],
    public prescription?: Prescription,
    public observations?: string,
    public pathologies?: Pathology[],
    public file?: File
  ) { }

  clone() {
    return new ApproachPrescription(
      this.hash,
      this.prescriptionHash,
      this.activityClub,
      this.activityCourse,
      this.morningShift,
      this.afternoonShift,
      this.monday,
      this.tuesday,
      this.wednesday,
      this.thursday,
      this.friday,
      this.saturday,
      this.sunday,
      this.outdoor,
      this.indoor,
      this.escolar,
      this.federated,
      this.social,
      this.instalationFadura,
      this.instalationGobela,
      this.instalationAndraMari,
      this.instalationRke,
      this.instalationOthers,
      this.benefits,
      this.prescription,
      this.observations,
      this.pathologies,
      this.file
    );
  }
}
