import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { hasRequiredField } from 'src/app/core/utils/form.utils';
import { TranslateService } from '@ngx-translate/core';
import { getMoment } from '../../../../core/utils/date.utils';

@Component({
  selector: 'app-datepicker-field',
  templateUrl: './datepicker-field.component.html',
  styleUrls: ['./datepicker-field.component.css']
})
export class DatepickerFieldComponent {
  @Input() id: string;
  @Input() controlName: string;
  @Input() key: string;
  @Input() placeholder = 'dashboard.prescription.identificationData.form.bornDate';
  @Input() type: 'both' | 'calendar' | 'timer' = 'both';
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() minDate: number;
  @Input() maxDate: number;
  @Input() form: FormGroup;
  @Output() onChange = new EventEmitter<any>();

  @ViewChild('datepicker') datepicker;

  inputDateData: string;
  currentLang: string;
  constructor(private translate: TranslateService) {
    this.currentLang = this.translate.currentLang;
  }

  get min() {
    return (this.minDate) ? getMoment(this.minDate) : null;
  }

  get max() {
    return (this.maxDate) ? getMoment(this.maxDate) : null;
  }

  openDatePicker(): void {
    this.datepicker.open();
  }

  onDateSelected(selectedDate: Date): void {
    if (selectedDate) {
      selectedDate.setHours(12);
      this.inputDateData = this.formatDate(selectedDate, this.currentLang);
    }
  }

  formatDate(date: Date, currentLang: string): string {
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const format = new Intl.DateTimeFormat(currentLang, options);
    return format.format(date);
  }

  hasRequiredField(control: AbstractControl) {
    return hasRequiredField(control);
  }
}
