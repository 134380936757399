import { mapCollection } from '../../utils/collection.utils';
import { ApproachPrescription } from '../approach-prescription';

export class ApproachPrescriptionBuilder {
  static fromJson(json: any): ApproachPrescription {
    return json
      ? new ApproachPrescription(
        json.hash,
        json.prescriptionHash,
        json.activityClub ? json.activityClub: false,
        json.activityCourse ? json.activityCourse: false,
        json.morningShift ? json.morningShift : false,
        json.afternoonShift ? json.afternoonShift : false,
        json.monday ? json.monday: false,
        json.tuesday ? json.tuesday: false,
        json.wednesday ? json.wednesday: false,
        json.thursday ? json.thursday: false,
        json.friday ? json.friday: false,
        json.saturday ? json.saturday: false,
        json.sunday ? json.sunday: false,
        json.outdoor ? json.outdoor : false,
        json.indoor ? json.indoor : false,
        json.escolar ? json.escolar: false,
        json.federated ? json.federated: false,
        json.social ? json.social: false,
        json.instalationFadura ? json.instalationFadura: false,
        json.instalationGobela ? json.instalationGobela: false,
        json.instalationAndraMari ? json.instalationAndraMari: false,
        json.instalationRke ? json.instalationRke: false,
        json.instalationOthers ? json.instalationOthers: false,
        json.benefits,
        json.prescription,
        json.observations,
        json.pathologies,
        json.file
      )
      : null;
  }

  static fromList(list: any): ApproachPrescription[] {
    return Array.isArray(list)
      ? mapCollection(ApproachPrescriptionBuilder.fromJson, list)
      : [];
  }
}
