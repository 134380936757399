import { Directive, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { PRESCRIPTION_STEP_RECOMMENDATION } from 'src/app/core/config/constants';
import { PrescriptionService } from '../../dashboard/prescription/prescription.service';

const STEP_ROUTE_MATCH = [
  { route: 'identification',  stepNumber: 1 },
  { route: 'forms',           stepNumber: 2 },
  { route: 'approach',        stepNumber: 3 },
  { route: 'recommendation',  stepNumber: 4 }
];


@Directive({
  selector: '[appFillStepper]'
})
export class FillStepperDirective implements OnInit {
  @Input() stepNumber: number;
  @Input() currentRoute: string;
  prescriptionLastStepNumber: number;

  constructor(private el: ElementRef, private renderer: Renderer2, private prescriptionService: PrescriptionService) { }

  ngOnInit(): void {
    this.prescriptionService.prescriptionLastStepNumber.subscribe(prescriptionLastStepNumber => {
      // if (prescriptionLastStepNumber === PRESCRIPTION_STEP_RESUME) {
      //   this.prescriptionLastStepNumber = prescriptionLastStepNumber + 1;
      // } else {
      //   this.prescriptionLastStepNumber = prescriptionLastStepNumber;
      // }
      this.prescriptionLastStepNumber = prescriptionLastStepNumber;
    });
    this.fillStep(this.stepNumber, this.currentRoute, this.prescriptionLastStepNumber);
  }

  fillStep(stepNumber: number, currentRoute: string, prescriptionLastStepNumber: number): void {

    const currentRouteNumber = STEP_ROUTE_MATCH.find(item => item.route === currentRoute).stepNumber;

    if (currentRouteNumber === stepNumber) {
      this.renderer.addClass(this.el.nativeElement, 'circle--half');
    }

    if ( prescriptionLastStepNumber >= stepNumber && currentRouteNumber !== stepNumber ) {
      this.renderer.addClass(this.el.nativeElement, 'circle--full');
    }
  }

}
