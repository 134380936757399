import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Activity } from 'src/app/core/models/activity';
import { ActivityPrescription } from 'src/app/core/models/activity-prescription';
import { ApproachPrescription } from 'src/app/core/models/approach-prescription';
import { ChangePrescription } from 'src/app/core/models/change-prescription';
import { HealthPrescription } from 'src/app/core/models/health-prescription';
import { PhysicalProfilePrescription } from 'src/app/core/models/physical-profile-prescription';
import { Prescription } from 'src/app/core/models/prescription';
import { RiskPrescription } from 'src/app/core/models/risk-prescription';
import { TestPrescription } from 'src/app/core/models/test-prescription';
import { PrescriptionApiService } from 'src/app/core/services/api/prescription-api.service';
import { ResolverService } from 'src/app/core/services/base/resolver.service';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {
  currentPrescriptionHash: BehaviorSubject<string> = new BehaviorSubject<string>('');
  currentClientHash: BehaviorSubject<string> = new BehaviorSubject<string>('');
  hasRiskPrescription: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasChangePrescription: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  editMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  viewMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasActivityPrescription: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  goBackStep: BehaviorSubject<string> = new BehaviorSubject<string>('');
  prescriptionLastStep: BehaviorSubject<string> = new BehaviorSubject<string>('');
  prescriptionLastStepNumber: BehaviorSubject<number> = new BehaviorSubject<number>(6);

  constructor(
    private prescriptionApiService: PrescriptionApiService,
    private resolverService: ResolverService
  ) { }

  updatePrescriptionHash(prescriptionHash: string) {
    this.currentPrescriptionHash.next(prescriptionHash);
  }

  updateClientHash(clientHash: string) {
    this.currentClientHash.next(clientHash);
  }

  updateHasRiskPrescription(hasRiskPrescription: boolean) {
    this.hasRiskPrescription.next(hasRiskPrescription);
  }

  updateHasChangePrescription(hasChangePrescription: boolean) {
    this.hasChangePrescription.next(hasChangePrescription);
  }

  updateEditMode(editMode: boolean) {
    this.editMode.next(editMode);
  }

  updateHasActivityPrescription(hasActivityPrescription: boolean) {
    this.hasActivityPrescription.next(hasActivityPrescription);
  }

  updateGoBackStep(step: string) {
    this.goBackStep.next(step);
  }

  updateViewMode(viewMode: boolean) {
    this.viewMode.next(viewMode);
  }

  updatePrescriptionLastStep(prescriptionLastStep: string) {
    this.prescriptionLastStep.next(prescriptionLastStep);
  }

  updatePrescriptionLastStepNumber(prescriptionLastStepNumber: number) {
    this.prescriptionLastStepNumber.next(prescriptionLastStepNumber);
  }

  // API related

  getPrescription(hash: string): Observable<Prescription> {
    return this.prescriptionApiService.getPrescription(hash);
  }

  createPrescription(clientHash: string) {
    return this.prescriptionApiService.createPrescription(clientHash).toPromise();
  }

  createHealthPrescription(formData: HealthPrescription) {
    return this.prescriptionApiService.createHealthPrescription(formData).toPromise();
  }

  copyHealthPrescription(prescriptionHash: string) {
    return this.prescriptionApiService.copyHealthPrescription(prescriptionHash).toPromise();
  }

  updateHealthPrescription(formData: HealthPrescription, hash: string) {
    return this.prescriptionApiService.updateHealthPrescription(formData, hash).toPromise();
  }

  resolveHealtPrescriptionByPrescription(hash: string) {
    this.resolverService.onResolverStart();
    return this.getHealthPrescriptionByPrescription(hash)
      .pipe(
        catchError(this.onClientResolveError),
        finalize(this.resolverService.onResolverStop)
      );
  }

  getHealthPrescriptionByPrescription(hash: string) {
    return this.prescriptionApiService.getHealthPrescriptionByPrescription(hash);
  }

  resolveActivityPrescriptionByPrescription(prescriptionHash: string) {
    this.resolverService.onResolverStart();
    const activityPrescription = this.prescriptionApiService.getActivityPrescriptionByPrescription(prescriptionHash)
    .pipe(
      catchError(this.onPrescriptionResolveError),
      finalize(this.resolverService.onResolverStop)
    );

    activityPrescription.subscribe(res => {
      if (res === null) {
        this.updateHasActivityPrescription(false);
      } else {
        this.updateHasActivityPrescription(true);
      }
    });

    return activityPrescription;
  }

  createActivityPrescription(activityPrescription: ActivityPrescription) {
    return this.prescriptionApiService.createActivityPrescription(activityPrescription).toPromise();
  }
  
  updateActivityPrescription(activityPrescriptionHash: string, activityPrescription: ActivityPrescription) {
    return this.prescriptionApiService.updateActivityPrescription(activityPrescriptionHash, activityPrescription).toPromise();
  }

  createRiskPrescription(riskPrescription: RiskPrescription) {
    return this.prescriptionApiService.createRiskPrescription(riskPrescription).toPromise();
  }
  
  updateRiskPrescription(riskPrescriptionHash: string, riskPrescription: RiskPrescription) {
    return this.prescriptionApiService.updateRiskPrescription(riskPrescription, riskPrescriptionHash).toPromise();
  }

  createChangePrescription(changePrescription: ChangePrescription) {
    return this.prescriptionApiService.createChangePrescription(changePrescription).toPromise();
  }
  
  updateChangePrescription(changePrescriptionHash: string, changePrescription: ChangePrescription) {
    return this.prescriptionApiService.updateChangePrescription(changePrescription, changePrescriptionHash).toPromise();
  }

  resolveChangePrescriptionByPrescription(prescriptionHash: string) {
    this.resolverService.onResolverStart();
    const changePrescription = this.prescriptionApiService.getChangePrescriptionByPrescription(prescriptionHash)
    .pipe(
      catchError(this.onPrescriptionResolveError),
      finalize(this.resolverService.onResolverStop)
    );

    changePrescription.subscribe(res => {
      if (res === null) {
        this.updateHasChangePrescription(false);
      } else {
        this.updateHasChangePrescription(true);
      }
    });

    return changePrescription;
  }

  resolveRiskPrescriptionByPrescription(prescriptionHash: string) {
    this.resolverService.onResolverStart();
    const riskPrescription = this.prescriptionApiService.getRiskPrescriptionByPrescription(prescriptionHash)
    .pipe(
      catchError(this.onPrescriptionResolveError),
      finalize(this.resolverService.onResolverStop)
    );

    riskPrescription.subscribe(res => {
      if (res === null) {
        this.updateHasRiskPrescription(false);
      } else {
        this.updateHasRiskPrescription(true);
      }
    });
    
    return riskPrescription;
  }

  createApproachPrescription(formData: ApproachPrescription, benefitsHashes: string[], pathologiesHashes: string[]) {
    return this.prescriptionApiService.createApproachPrescription(formData, benefitsHashes, pathologiesHashes).toPromise();
  }

  updateApproachPrescription(formData: ApproachPrescription, benefitsHashes: string[], pathologiesHashes: string[], hash: string) {
    return this.prescriptionApiService.updateApproachPrescription(formData, benefitsHashes, pathologiesHashes, hash).toPromise();
  }

  uploadPrescriptionFile(hash:string, file: File) {
    return this.prescriptionApiService.uploadPrescriptionFile(hash, file).toPromise();
  }

  resolveApproachPrescription(hash: string) {
    this.resolverService.onResolverStart();
    return this.getApproachPrescription(hash)
      .pipe(
        catchError(this.onClientResolveError),
        finalize(this.resolverService.onResolverStop)
      );
  }

  getApproachPrescription(hash: string) {
    return this.prescriptionApiService.getApproachPrescriptionByPrescription(hash);
  }

  resolvePrescriptionsByClient(clientHash: string) {
    this.resolverService.onResolverStart();
    return this.getPrescriptionsByClient(clientHash)
      .pipe(
        catchError(this.onClientResolveError),
        finalize(this.resolverService.onResolverStop)
      );
  }

  getPrescriptionsByClient(clientHash: string) {
    return this.prescriptionApiService.getPrescriptionsByClient(clientHash);
  }


  createPhysicalProfilePrescription(formData: PhysicalProfilePrescription) {
    return this.prescriptionApiService.createPhysicalProfilePrescription(formData).toPromise();
  }

  updatePhysicalProfilePrescription(formData: PhysicalProfilePrescription, hash: string) {
    return this.prescriptionApiService.updatePhysicalProfilePrescription(formData, hash).toPromise();
  }

  resolvePhysicalProfilePrescription(hash: string) {
    this.resolverService.onResolverStart();
    return this.getPhysicalProfilePrescription(hash)
      .pipe(
        catchError(this.onClientResolveError),
        finalize(this.resolverService.onResolverStop)
      );
  }

  getPhysicalProfilePrescription(hash: string) {
    return this.prescriptionApiService.getPhysicalProfilePrescriptionByPrescription(hash);
  }

  createTestPrescription(formData: TestPrescription) {
    return this.prescriptionApiService.createTestPrescription(formData).toPromise();
  }

  updateTestPrescription(formData: TestPrescription, hash: string) {
    return this.prescriptionApiService.updateTestPrescription(formData, hash).toPromise();
  }

  getRecommendedActivitiesByPrescription(prescriptionHash: string): Observable<Activity[]> {
    return this.prescriptionApiService.getRecommendedActivitiesByPrescription(prescriptionHash);
  }

  resolveTestPrescriptions(hash: string) {
    this.resolverService.onResolverStart();
    return this.getTestPrescriptions(hash)
      .pipe(
        catchError(this.onClientResolveError),
        finalize(this.resolverService.onResolverStop)
      );
  }

  resolveRecommendedActivitiesByPrescription(prescriptionHash: string): Observable<Activity[]> {
    this.resolverService.onResolverStart();
    return this.getRecommendedActivitiesByPrescription(prescriptionHash)
      .pipe(
        catchError(this.onClientResolveError),
        finalize(this.resolverService.onResolverStop)
      );
  }

  getTestPrescriptions(hash: string) {
    return this.prescriptionApiService.getTestPrescriptionsByPrescription(hash);
  }

  private onClientResolveError = () => this.resolverService
    .onResolverError('/clients', 'error.client_not_found');
    
  private onPrescriptionResolveError = () => this.resolverService
    .onResolverError('/risk', 'error.riskPrescription_not_found');
}
