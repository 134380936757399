import { Pathology } from './pathology';
import { Prescription } from './prescription';

export class HealthPrescription {
  constructor(
    public hash?: string,
    public prescriptionHash?: string,
    public answer1?: boolean,
    public answer2?: boolean,
    public answer3?: boolean,
    public answer4?: boolean,
    public answer5?: boolean,
    public answer6?: boolean,
    public answer7?: boolean,
    public prescription?: Prescription
  ) { }

  clone() {
    return new HealthPrescription(
      this.hash,
      this.prescriptionHash,
      this.answer1,
      this.answer2,
      this.answer3,
      this.answer4,
      this.answer5,
      this.answer6,
      this.answer7,
      this.prescription
    );
  }
}
