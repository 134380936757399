export const PRELOAD_MODULES = true;

export const PAGE_ELEMENTS = 10;

export const DATA_TITLE = 'title';
export const DATA_BACK_LINK = 'backLink';

export const PRESCRIPTIONS_URL_HASH_INDEX = 0;
export const PRESCRIPTIONS_URL_STEP_INDEX = 1;

export const MET_WALKING = 3.3;
export const MET_MODERATE = 4;
export const MET_VIGOROUS = 8;

export const IPAQ_INACTIVE_LIMIT = 600;
export const IPAQ_MINIMALLY_LIMIT = 1500;
export const IPAQ_ACTIVE_LIMIT = 3000;

export const LANGUAGE_ES = 'es';
export const LANGUAGE_EU = 'eu';
export const LANGUAGE_EN = 'en';
export const DEFAULT_LANGUAGE = LANGUAGE_ES;

// export const PRESCRIPTION_STEP_IDENTIFICATION = 1;
// export const PRESCRIPTION_STEP_HEALTH = 2;
// export const PRESCRIPTION_STEP_RISK = 3;
// export const PRESCRIPTION_STEP_ACTIVITY = 4;
// export const PRESCRIPTION_STEP_CHANGE = 5;
// export const PRESCRIPTION_STEP_APPROACH = 6;
// export const PRESCRIPTION_STEP_RESUME = 7;
// export const PRESCRIPTION_STEP_RECOMMENDATION = 8;

export const PRESCRIPTION_STEP_IDENTIFICATION = 1;
export const PRESCRIPTION_STEP_FORMS = 2;
export const PRESCRIPTION_STEP_APPROACH = 3;
export const PRESCRIPTION_STEP_RECOMMENDATION = 4;
