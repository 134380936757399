import { mapCollection } from '../../utils/collection.utils';
import { HealthPrescription } from '../health-prescription';

export class HealthPrescriptionBuilder {
  static fromJson(json: any): HealthPrescription {
    return json
      ? new HealthPrescription(
        json.hash,
        json.prescriptionHash,
        json.answer1,
        json.answer2,
        json.answer3,
        json.answer4,
        json.answer5,
        json.answer6,
        json.answer7,
        json.prescription
      )
      : null;
  }

  static fromList(list: any): HealthPrescription[] {
    return Array.isArray(list)
      ? mapCollection(HealthPrescriptionBuilder.fromJson, list)
      : [];
  }
}
