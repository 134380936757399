import { Injectable } from "@angular/core";
import { Appointment } from "../../models/appointment";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { AppointmentBuilder } from "../../models/builder/appointment.builder";
import { Observable } from "rxjs";

const saveAppointmentBody = (appointment: Appointment) => ({
    name: appointment.name,
    surname: appointment.surnames,
    telephone: appointment.phoneNumber,
    appointmentStart: appointment.appointmentDate,
    appointmentEnd: appointment.appointmentDateEnd,
});

@Injectable()
export class AppointmentApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}appointment`;
  }
    createAppointment(appointment: Appointment) {
      return this.httpClient.post(this.apiUrl, saveAppointmentBody(appointment))
      .pipe(
          map(AppointmentBuilder.fromJson)
      );
    }

    updateAppointment(appointment: Appointment, hash: string) {
      return this.httpClient.put(`${this.apiUrl}/${hash}`, saveAppointmentBody(appointment))
      .pipe(
          map(AppointmentBuilder.fromJson)
      );
    }

    deleteAppointment(hash: string) {
      return this.httpClient.delete(`${this.apiUrl}/${hash}`);
    }
    
    getAppointentsByDate(date) {
      return this.httpClient.get(`${this.apiUrl}/date/${date}`)
      .pipe(
        map(AppointmentBuilder.fromList)
      );
    }
    
    getAppointentsByHash(hash): Observable<Appointment> {
      return this.httpClient.get(`${this.apiUrl}/hash/${hash}`)
      .pipe(
        map(AppointmentBuilder.fromJson)
      );
    }
}