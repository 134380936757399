export const loginRoute = '/login';

export const loggedInRoute = '';

export const notFoundRoute = '/404';

export const defaultUserRoute = '/home';


// export const STEP_ROUTE_MATCH = [
//   { route: 'identification', stepNumber: 1 },
//   { route: 'health', stepNumber: 2 },
//   { route: 'risk', stepNumber: 3 },
//   { route: 'activity', stepNumber: 4 },
//   { route: 'change', stepNumber: 5 },
//   { route: 'approach', stepNumber: 6 }
// ];


export const STEP_ROUTE_MATCH = [
  { route: 'identification', stepNumber: 1 },
  { route: 'forms', stepNumber: 2 },
  { route: 'approach', stepNumber: 3 }
];
