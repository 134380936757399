import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Activity } from '../../models/activity';
import { ActivityPrescription } from '../../models/activity-prescription';
import { ApproachPrescription } from '../../models/approach-prescription';
import { ActivityPrescriptionBuilder } from '../../models/builder/activity-prescription.builder';
import { ActivityBuilder } from '../../models/builder/activity.builder';
import { ApproachPrescriptionBuilder } from '../../models/builder/approach-prescription.builder';
import { ChangePrescriptionBuilder } from '../../models/builder/change-prescription.builder';
import { HealthPrescriptionBuilder } from '../../models/builder/health-prescription.builder';
import { PhysicalProfilePrescriptionBuilder } from '../../models/builder/physical-profile-prescription.builder';
import { PrescriptionBuilder } from '../../models/builder/prescription.builder';
import { RiskPrescriptionBuilder } from '../../models/builder/risk-prescription.builder';
import { TestPrescriptionBuilder } from '../../models/builder/test-prescription.builder';
import { ChangePrescription } from '../../models/change-prescription';
import { HealthPrescription } from '../../models/health-prescription';
import { PhysicalProfilePrescription } from '../../models/physical-profile-prescription';
import { Prescription } from '../../models/prescription';
import { RiskPrescription } from '../../models/risk-prescription';
import { TestPrescription } from '../../models/test-prescription';
import { getAvoidErrorHandlingHeader } from '../base/headers';

const saveHealthPrescriptionBody = (healthPrescription: HealthPrescription) => ({
  prescriptionHash: healthPrescription.prescriptionHash,
  answer1: healthPrescription.answer1,
  answer2: healthPrescription.answer2,
  answer3: healthPrescription.answer3,
  answer4: healthPrescription.answer4,
  answer5: healthPrescription.answer5,
  answer6: healthPrescription.answer6,
  answer7: healthPrescription.answer7
});

const createHealthPrescriptionWithFile = (healthPrescription: HealthPrescription) => {
  const postBody = new FormData();
  postBody.append('healthPrescription', JSON.stringify(saveHealthPrescriptionBody(healthPrescription)));
  
  return postBody;
};

const createApproachPrescriptionWithFile = (approachPrescription: ApproachPrescription, benefitHashes: string[], pathologiesHashes: string[]) => {
  const postBody = new FormData();
  postBody.append('approachPrescription', JSON.stringify(saveApproachPrescriptionBody(approachPrescription, benefitHashes, pathologiesHashes)));
  
  if (approachPrescription.file) postBody.append('file', approachPrescription.file);

  return postBody;
};

const savePrescriptionBody = (clientHash: string) => ({
  clientHash
});

const saveRiskPrescriptionBody = (riskPrescription) => ({
  ...riskPrescription
});

const saveActivityPrescriptionBody = (activityPrescription: any, prescriptionHash?: string) => ({
  ...activityPrescription,
  prescriptionHash
});

const saveChangePrescriptionBody = (changePrescription) => ({
  ...changePrescription
});

const saveApproachPrescriptionBody = (approachPrescription: ApproachPrescription, benefitHashes: string[], pathologiesHashes: string[]) => ({
  prescriptionHash: approachPrescription.prescriptionHash,
  activityClub: approachPrescription.activityClub,
  activityCourse: approachPrescription.activityCourse,
  morningShift: approachPrescription.morningShift,
  afternoonShift: approachPrescription.afternoonShift,
  monday: approachPrescription.monday,
  tuesday: approachPrescription.tuesday,
  wednesday: approachPrescription.wednesday,
  thursday: approachPrescription.thursday,
  friday: approachPrescription.friday,
  saturday: approachPrescription.saturday,
  sunday: approachPrescription.sunday,
  outdoor: approachPrescription.outdoor,
  indoor: approachPrescription.indoor,
  escolar: approachPrescription.escolar,
  federated: approachPrescription.federated,
  social: approachPrescription.social,
  instalationFadura: approachPrescription.instalationFadura,
  instalationGobela: approachPrescription.instalationGobela,
  instalationAndraMari: approachPrescription.instalationAndraMari,
  instalationRke: approachPrescription.instalationRke,
  instalationOthers: approachPrescription.instalationOthers,
  observations: approachPrescription.observations,
  benefits: benefitHashes,
  pathologies: pathologiesHashes
});

const savePhysicalProfilePrescriptionBody = (physicalProfilePrescription) => ({
  ...physicalProfilePrescription
});

const saveTestPrescriptionBody = (testPrescription) => ({
  prescriptionHash: testPrescription.prescription.hash,
  testHash: testPrescription.test.hash,
  result1: testPrescription.result1,
  result2: testPrescription.result2,
  result3: testPrescription.result3,
  result4: testPrescription.result4,
  note: testPrescription.note,
});

@Injectable()
export class PrescriptionApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient,
  ) {
    this.apiUrl = `${environment.apiUrl}prescriptions`;
  }

  createPrescription(clientHash: string) {
    return this.httpClient.post(this.apiUrl, savePrescriptionBody(clientHash))
      .pipe(
        map(PrescriptionBuilder.fromJson)
      );
  }

  getPrescriptions() {
    return this.httpClient.get(`${this.apiUrl}`).pipe(
      map(PrescriptionBuilder.fromList)
    );
  }

  getPrescription(hash: string): Observable<Prescription> {
    return this.httpClient.get(`${this.apiUrl}/${hash}`).pipe(
      map(PrescriptionBuilder.fromJson)
    );
  }

  // RiskPrescription

  getRiskPrescriptionByPrescription(prescriptionHash: string) {
    const params = new HttpParams().set('prescriptionHash', prescriptionHash);

    return this.httpClient.get(`${this.apiUrl}/risk`, { params })
      .pipe(
        map(RiskPrescriptionBuilder.fromJson)
      );
  }

  createRiskPrescription(riskPrescription: any): Observable<RiskPrescription> {
    const { prescriptionHash } = riskPrescription;
    const params = new HttpParams().set('prescriptionHash', prescriptionHash);

    return this.httpClient.post(`${this.apiUrl}/risk`, saveRiskPrescriptionBody(riskPrescription), { params })
      .pipe(
        map(RiskPrescriptionBuilder.fromJson)
      );
  }

  updateRiskPrescription(riskPrescription: any, riskPrescriptionHash: string): Observable<RiskPrescription> {
    return this.httpClient.put(`${this.apiUrl}/risk/${riskPrescriptionHash}`, saveRiskPrescriptionBody(riskPrescription))
      .pipe(
        map(RiskPrescriptionBuilder.fromJson)
      );
  }

  // HealthPrescription
  getHealthPrescriptionByPrescription(hash: string) {
    const params = new HttpParams().set('prescriptionHash', hash);
    
    return this.httpClient.get(`${this.apiUrl}/health`, { params })
    .pipe(
      map(HealthPrescriptionBuilder.fromJson)
    );
  }

  createHealthPrescription(healthPrescription: HealthPrescription) {
    return this.httpClient.post(`${this.apiUrl}/health`, createHealthPrescriptionWithFile(healthPrescription))
    .pipe(
      map(PrescriptionBuilder.fromJson)
    );
  }

  updateHealthPrescription(healthPrescription: HealthPrescription, hash: string) {
    return this.httpClient.put(`${this.apiUrl}/health/${hash}`, createHealthPrescriptionWithFile(healthPrescription))
    .pipe(
      map(PrescriptionBuilder.fromJson)
    );
  }

  // ActivityPrescription
  getActivityPrescriptionByPrescription(prescriptionHash: string) {
    const params = new HttpParams().set('prescriptionHash', prescriptionHash);

    return this.httpClient.get(`${this.apiUrl}/activity`, { params })
    .pipe(
      map(ActivityPrescriptionBuilder.fromJson)
    );
  }

  createActivityPrescription(activityPrescription: ActivityPrescription): Observable<ActivityPrescription> {
    const { prescriptionHash } = activityPrescription;

    return this.httpClient.post(`${this.apiUrl}/activity`, saveActivityPrescriptionBody(activityPrescription, prescriptionHash))
    .pipe(
      map(ActivityPrescriptionBuilder.fromJson)
    );
  }

  updateActivityPrescription(activityPrescriptionHash: string, activityPrescription: ActivityPrescription): Observable<ActivityPrescription> {
    const { prescriptionHash } = activityPrescription;

    return this.httpClient.put(`${this.apiUrl}/activity/${activityPrescriptionHash}`, saveActivityPrescriptionBody(activityPrescription, prescriptionHash))
    .pipe(
      map(ActivityPrescriptionBuilder.fromJson)
    );
  }

  // ChangePrescription
  getChangePrescriptionByPrescription(prescriptionHash: string) {
    const params = new HttpParams().set('prescriptionHash', prescriptionHash);

    return this.httpClient.get(`${this.apiUrl}/change`, { params })
      .pipe(
        map(ChangePrescriptionBuilder.fromJson)
      );
  }

  createChangePrescription(changePrescription: any): Observable<ChangePrescription> {
    const { prescriptionHash } = changePrescription;
    const params = new HttpParams().set('prescriptionHash', prescriptionHash);

    return this.httpClient.post(`${this.apiUrl}/change`, saveChangePrescriptionBody(changePrescription), { params })
      .pipe(
        map(ChangePrescriptionBuilder.fromJson)
      );
  }

  updateChangePrescription(changePrescription: any, changePrescriptionHash: string): Observable<ChangePrescription> {
    return this.httpClient.put(`${this.apiUrl}/change/${changePrescriptionHash}`, saveChangePrescriptionBody(changePrescription))
      .pipe(
        map(ChangePrescriptionBuilder.fromJson)
      );
  }

  // ApproachPrescription

  getApproachPrescriptionByPrescription(hash: string) {
    let params = new HttpParams();
    params = params.append('prescriptionHash', hash);
    return this.httpClient.get(`${this.apiUrl}/approach`, { params })
      .pipe(
        map(ApproachPrescriptionBuilder.fromJson)
      );
  }

  createApproachPrescription(approachPrescription: ApproachPrescription, benefitHashes: string[], pathologiesHashes: string[]) {
    return this.httpClient.post(`${this.apiUrl}/approach`, createApproachPrescriptionWithFile(approachPrescription, benefitHashes, pathologiesHashes))
      .pipe(
        map(PrescriptionBuilder.fromJson)
      );
  }

  updateApproachPrescription(approachPrescription: ApproachPrescription, benefitHashes: string[], pathologiesHashes: string[], hash: string) {
    return this.httpClient.put(`${this.apiUrl}/approach/${hash}`, createApproachPrescriptionWithFile(approachPrescription, benefitHashes, pathologiesHashes))
      .pipe(
        map(PrescriptionBuilder.fromJson)
      );
  }

  uploadPrescriptionFile(hash:string, file: File) { 
    const postBody = new FormData();
    postBody.append('file', file);

    return this.httpClient.put(`${this.apiUrl}/approach/${hash}/upload-file`, postBody)
      .pipe(
        map(ApproachPrescriptionBuilder.fromJson)
      );
  }

  getPrescriptionsByClient(clientHash: string) {
    const params = new HttpParams().set('clientHash', clientHash);

    return this.httpClient.get(`${this.apiUrl}`, { params })
      .pipe(
        map(PrescriptionBuilder.fromList)
      );
  }

  copyHealthPrescription(prescriptionHash: string) {
    const headers = getAvoidErrorHandlingHeader();

    return this.httpClient.post(`${this.apiUrl}/health/copy/${prescriptionHash}`, {}, { headers })
      .pipe(
        map(HealthPrescriptionBuilder.fromJson)
      );
  }

  getPhysicalProfilePrescriptionByPrescription(hash: string) {
    let params = new HttpParams();
    params = params.append('prescriptionHash', hash);
    return this.httpClient.get(`${this.apiUrl}/physical-profile`, { params })
      .pipe(
        map(PhysicalProfilePrescriptionBuilder.fromJson)
      );
  }

  createPhysicalProfilePrescription(physicalProfilePrescription: PhysicalProfilePrescription) {
    return this.httpClient.post(`${this.apiUrl}/physical-profile`, savePhysicalProfilePrescriptionBody(physicalProfilePrescription))
      .pipe(
        map(PhysicalProfilePrescriptionBuilder.fromJson)
      );
  }

  updatePhysicalProfilePrescription(physicalProfilePrescription: PhysicalProfilePrescription, hash: string) {
    return this.httpClient.put(`${this.apiUrl}/physical-profile/${hash}`, savePhysicalProfilePrescriptionBody(physicalProfilePrescription))
      .pipe(
        map(PhysicalProfilePrescriptionBuilder.fromJson)
      );
  }

  getTestPrescriptionsByPrescription(hash: string) {
    let params = new HttpParams();
    params = params.append('prescriptionHash', hash);
    return this.httpClient.get(`${this.apiUrl}/test`, { params })
      .pipe(
        map(TestPrescriptionBuilder.fromList)
      );
  }

  createTestPrescription(testPrescription: TestPrescription) {
    return this.httpClient.post(`${this.apiUrl}/test`, saveTestPrescriptionBody(testPrescription))
      .pipe(
        map(TestPrescriptionBuilder.fromJson)
      );
  }

  updateTestPrescription(testPrescription: TestPrescription, hash: string) {
    return this.httpClient.put(`${this.apiUrl}/test/${hash}`, saveTestPrescriptionBody(testPrescription))
      .pipe(
        map(TestPrescriptionBuilder.fromJson)
      );
  }

  getRecommendedActivitiesByPrescription(prescriptionHash: string): Observable<Activity[]> {
    return this.httpClient.get(`${this.apiUrl}/activities/${prescriptionHash}`)
      .pipe(
        map(ActivityBuilder.fromList)
      );
  }

}
