import { Component, Input } from '@angular/core';
import { Activity } from 'src/app/core/models/activity';
import { server } from 'src/environments/environment';

@Component({
  selector: 'app-recommendation-activity-modal',
  templateUrl: './recommendation-activity-modal.component.html',
  styleUrls: ['./recommendation-activity-modal.component.css']
})
export class RecommendationActivityModalComponent {
  @Input() activity: Activity;
  @Input() weekdayNames: string;

  isOpen: boolean = false;
  serverUrl = server;

  open(): void {
    this.isOpen = true;
  }

  close(): void {
    this.isOpen = false;
  }
}
