import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgxSmartModalComponent } from 'ngx-smart-modal';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() messageParams = {};
  @Input() confirmLabel = 'shared.confirm.confirm';
  @Input() cancelLabel = 'shared.confirm.cancel';
  @Input() hideHeader = false;
  @Input() onSave!: () => void;
  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  isOpen: boolean = false;

  open(): void {
    this.isOpen = true;
  }

  close(): void {
    this.isOpen = false;
  }

  onCancel() {
    this.isOpen = false;
  }

  onConfirm() {
    this.confirm.emit();
    this.close();
  }
}
